import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, createBlock as _createBlock, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_tabs = _resolveComponent("tm-tabs")!
  const _component_contacts_import_filter = _resolveComponent("contacts-import-filter")!
  const _component_contacts_import_details_table = _resolveComponent("contacts-import-details-table")!
  const _component_tm_empty_state = _resolveComponent("tm-empty-state")!
  const _component_page_block = _resolveComponent("page-block")!

  return (_openBlock(), _createBlock(_component_page_block, {
    breadcrumbs: _ctx.breadcrumbsLink,
    title: "McKeePoP013.xlsx",
    size: "full"
  }, {
    "white-wrapper": _withCtx(() => [
      _createVNode(_component_tm_tabs, {
        modelValue: _ctx.tabsResult,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.tabsResult) = $event)),
        "show-counter-zero": "",
        tabs: _ctx.tabs
      }, null, 8, ["modelValue", "tabs"]),
      (!_ctx.isEmptyMode)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createVNode(_component_contacts_import_filter, {
              "hide-search": "",
              class: "mt-4"
            }),
            _createVNode(_component_contacts_import_details_table, {
              headers: _ctx.tableHeaders,
              items: _ctx.tableItems,
              "tabs-result": _ctx.tabsResult,
              "item-key": "id",
              "items-name": "contacts"
            }, null, 8, ["headers", "items", "tabs-result"])
          ], 64))
        : (_openBlock(), _createBlock(_component_tm_empty_state, _mergeProps({
            key: 1,
            "background-lift": "",
            class: "mt-4"
          }, _ctx.emptyStateBind), null, 16))
    ]),
    _: 1
  }, 8, ["breadcrumbs"]))
}