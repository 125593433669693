import { useModals } from '@/compositions/modals'

type UseImportOpenModal = () => {
  openModalDelete: (name: string[] | string) => void
}

const useImportOpenModal: UseImportOpenModal = () => {
  const { openModal } = useModals()

  const openModalDelete = (name: string[] | string) => {
    openModal(
      'contactsImportDelete',
      {
        name: (typeof name === 'string') ? [name] : name,
      }
    )
  }

  return {
    openModalDelete,
  }
}

export default useImportOpenModal
