
import type { PropType } from 'vue'
import { defineComponent, toRefs, computed } from 'vue'
import TopFilter from '@/components/layout/topFilter/TopFilter.vue'
import TopFilterButton from '@/components/layout/topFilter/TopFilterButton.vue'
import type { ContactImport } from '@/definitions/contacts/import/types'
import useImportOpenModal from '@/compositions/contacts/import/useImportOpenModal'

export default defineComponent({
  name: 'ContactsImportFilter',
  components: {
    TopFilterButton,
    TopFilter,
  },
  props: {
    selected: {
      type: Array as PropType<ContactImport[]>,
      default: () => [],
    },
  },
  setup(props) {
    const { selected } = toRefs(props)
    const { openModalDelete } = useImportOpenModal()

    const selectedName = computed(() => selected.value?.map((field) => field.fileName))

    return {
      openModalDelete,
      selectedName,
    }
  },
})
