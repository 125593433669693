
import { defineComponent, ref, computed } from 'vue'
import PageBlock from '@/components/layout/PageBlock.vue'
import ContactsImportDetailsTable from '@/components/pages/contacts/import/details/ContactsImportDetailsTable.vue'
import ContactsImportFilter from '@/components/pages/contacts/import/ContactsImportFilter.vue'
import TmTabs from '@/components/shared/TmTabs.vue'
import { getTableData } from '@/services/tableService'
import type { BreadcrumbsLink, Tab, TableHeaders } from '@/definitions/shared/types'
import type { ContactImportDetails, ContactImportDetailsResultVariant } from '@/definitions/contacts/import/types'
import { useModes } from '@/compositions/modes'
import TmEmptyState from '@/components/shared/states/TmEmptyState.vue'

const breadcrumbsLink: BreadcrumbsLink[] = [
  {
    label: 'Contacts',
    name: 'base.contacts.lists',
  },
  {
    label: 'Imports',
    name: 'base.contacts.import',
  },
  {
    label: 'McKeePoP013.xlsx',
  },
]

const tabsVariant: Tab[] = [
  {
    name: 'imported',
    label: 'Imported',
    counterClass: 'font-weight-semi-bold',
  },
  {
    name: 'duplicates',
    label: 'Duplicates excluded',
    counterClass: 'font-weight-semi-bold',
  },
  {
    name: 'failed',
    label: 'Failed to import',
    counterClass: 'red--text',
  },
]

export default defineComponent({
  name: 'ContactsImportDetails',
  components: {
    TmEmptyState,
    TmTabs,
    PageBlock,
    ContactsImportDetailsTable,
    ContactsImportFilter,
  },
  setup() {
    const { isEmptyMode } = useModes()

    const tableHeaders = ref<TableHeaders[]>([
      { text: 'First name', value: 'first-name-slot' },
      { text: 'Last name', value: 'last-name-slot' },
      { text: 'Phone', value: 'phone-slot' },
      { text: 'Email', value: 'email-slot' },
      { text: 'Status', value: 'status' },
      { text: 'Lists', value: 'lists-slot' },
    ])
    const tableItemsAll = ref<ContactImportDetails[]>(getTableData('contactsImportDetails'))

    const tabs = computed<Tab[]>(() => tabsVariant.map((el) => ({
      ...el,
      counter: isEmptyMode.value ? 0 : tableItemsAll.value.filter((item) => (item.result.value === el.name)).length,
    })))

    const tabsResult = ref<ContactImportDetailsResultVariant>('imported')

    const tableItems = computed<ContactImportDetails[]>(() => tableItemsAll.value.filter(
      (item) => (item.result.value === tabsResult.value)
    ))

    const emptyStateBind = computed(() => {
      switch (tabsResult.value) {
        case 'imported': return { title: 'This import session had no successfully imported contacts', description: 'Please check the “Failed to import” tab to fix possible validation errors. ', icon: 'tmi-person-remove' }
        case 'duplicates': return { title: 'This import session had no excluded duplicate numbers', description: 'There is nothing to fix, everything is alright here.', icon: 'tmi-person-check' }
        default: return { title: 'This import session had no failed rows', description: 'There is nothing to fix, everything is alright here.', icon: 'tmi-person-check' }
      }
    })

    return {
      tabs,
      tabsResult,
      tableHeaders,
      tableItems,
      emptyStateBind,
      isEmptyMode,
      breadcrumbsLink,
    }
  },
})
