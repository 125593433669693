
import type { PropType } from 'vue'
import { defineComponent, computed, toRefs } from 'vue'
import TmTable from '@/components/shared/table/TmTable.vue'
import TmPerson from '@/components/shared/TmPerson.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmBadge from '@/components/shared/TmBadge.vue'
import type { TableHeaders } from '@/definitions/shared/types'
import type { ContactImportDetails, ContactImportDetailsResultVariant } from '@/definitions/contacts/import/types'
import { useModals } from '@/compositions/modals'
import { useBreakpoints } from '@/compositions/breakpoints'

export default defineComponent({
  name: 'ContactsImportDetailsTable',
  components: {
    TmTable,
    TmPerson,
    TmButton,
    TmBadge,
  },
  props: {
    headers: {
      type: Array as PropType<TableHeaders[]>,
      required: true,
    },
    items: {
      type: Array as PropType<ContactImportDetails[]>,
      required: true,
    },
    itemsName: {
      type: String,
      required: true,
    },
    itemKey: {
      type: String,
      default: 'id',
    },
    showSelection: {
      type: Boolean,
    },
    tabsResult: {
      type: String as PropType<ContactImportDetailsResultVariant>,
      required: true,
    },
  },
  setup(props) {
    const { openModal } = useModals()
    const { headers, tabsResult } = toRefs(props)
    const { isMdMax } = useBreakpoints()

    const failedPage = computed(() => (tabsResult.value === 'failed'))

    const headersFilter = computed(() => (headers.value.filter(
      (column) => (!failedPage.value || !['Status', 'Lists'].includes(column.text))
    )))

    const openContactModal = () => {
      openModal('contact', {
        isEditing: true,
        errorState: true,
      })
    }

    return {
      isMdMax,
      headersFilter,
      failedPage,
      openContactModal,
    }
  },
})
